import React, { Component } from "react";
import queryString from "query-string";

// Global Components
import Container from "../../components/Container";
import OrdersPaginationTable from "../../components/OrdersPaginationTable";
import Spinner from "../../components/Spinner";

// Material
import { TextField, MenuItem, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CompartmentActions } from "../../store/ducks/compartment";
import { Actions as LockerActions } from "../../store/ducks/locker";
import { Actions as UserActions } from "../../store/ducks/users";
import { Actions as OrderActions } from "../../store/ducks/orders";

// Global style
import { materialStyle } from "../../styles";

import ButtonClick from "../../components/ButtonClick";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import FilterTitle from "../../components/FilterTitle";
import { withTranslation } from "react-i18next";

class Order extends Component {
  state = {
    email: "",
    date: "",
    idLocker: "",
    idCompartment: "",
    finishedAt: "",
    isFinished: 1,
    createdAt: null
  };

  componentWillMount() {
    const { lockersRequest, getActiveOrdersRequest } = this.props;
    const { isFinished } = this.state;

    lockersRequest(`perPage=1000`);
    getActiveOrdersRequest(`isFinished=${isFinished}`);
  }

  t = this.props.t;

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleChangeDate = createdAt => {
    this.setState({ createdAt });
  };

  handleSearch = () => {
    const { getActiveOrdersRequest } = this.props;
    const { email, idLocker, isFinished } = this.state;

    const data = {
      isFinished
    };

    if (email) {
      Object.assign(data, {
        email
      });
    }

    if (idLocker) {
      Object.assign(data, {
        idLocker
      });
    }

    getActiveOrdersRequest(queryString.stringify(data));
  };

  clearState = () => {
    this.setState({
      email: "",
      createdAt: "",
      idLocker: "",
      isFinished: 1
    });

    const { getActiveOrdersRequest } = this.props;

    getActiveOrdersRequest(`isFinished=${this.state.isFinished}`);
  };

  render() {
    const { classes, history, orders } = this.props;
    const { email, isFinished } = this.state;

    return (
      <Container>
        {orders.loading ? (
          <Spinner />
        ) : (
          <ThemeTextFildGreen>
            <FilterTitle />
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  label={"email"}
                  className={classes.textField1}
                  value={email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomAutoComplete
                  options={
                    this.props.locker.data.length ? this.props.locker.data : []
                  }
                  value={this.props.locker.data.find(
                    item => item.id === this.state.idLocker
                  )}
                  onChange={newValue => {
                    this.setState({
                      idLocker: newValue.id
                    });
                  }}
                  disabled={
                    this.props.locker.loading || !this.props.locker.data.length
                  }
                  loading={this.props.locker.loading}
                  getOptionLabelKey="address"
                  label="Locker"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="custom-css-outlined-input"
                  select
                  label="Status"
                  className={classes.textField1}
                  value={isFinished}
                  onChange={this.handleChange("isFinished")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  <MenuItem key={1} value={1}>
                    Ativas
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    Todas
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <ButtonClick onClick={this.handleSearch}>Buscar</ButtonClick>
            <ButtonClick onClick={this.clearState}>Limpar</ButtonClick>

            <OrdersPaginationTable history={history} />
          </ThemeTextFildGreen>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  locker: state.locker,
  compartment: state.compartment,
  orders: state.orders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CompartmentActions,
      ...LockerActions,
      ...UserActions,
      ...OrderActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("order")(withStyles(materialStyle)(Order)));

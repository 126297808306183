import DateFnsUtils from "@date-io/date-fns";
import { Grid, MenuItem, TextField, withStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React, { useState } from "react";
import { materialStyle } from "../../styles";
import ButtonClick from "../ButtonClick";
import { constants } from "../../config";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { enUS, ptBR } from "date-fns/locale";
import i18n from "../../i18n";

function DateRange({
  classes,
  onChangeFirstDate,
  onChangeFinalDate,
  search,
  currentPeriod,
  onChangePeriod,
  t
}) {
  const [initialDate, setInitialDate] = useState(
    new Date(new Date().setDate(1))
  );
  const [finalDate, setFinalDate] = useState(new Date());
  const [period, setPeriod] = useState(currentPeriod);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Grid item xs={12} md={2}>
        <TextField
          id="custom-css-outlined-input"
          select
          label={t("select_period")}
          className={classes.textField1}
          value={period}
          onChange={e => {
            const newValue = constants.periodOptions[e.target.value];

            setPeriod(e.target.value);
            setInitialDate(newValue.start_date);
            setFinalDate(newValue.final_date);

            onChangeFirstDate(
              new Date(newValue.start_date).toISOString().split("T")[0]
            );
            onChangeFinalDate(
              new Date(newValue.final_date).toISOString().split("T")[0]
            );
            onChangePeriod(e.target.value);
          }}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
        >
          {Object.keys(constants.periodOptions).map(status => (
            <MenuItem
              key={constants.periodOptions[status].key}
              value={constants.periodOptions[status].key}
            >
              {i18next.t(constants.periodOptions[status].label)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={i18n.language === "pt-BR" ? ptBR : enUS}
      >
        <Grid item xs={12} md={2}>
          <KeyboardDatePicker
            className={classes.textField1}
            disabled={period !== "period"}
            disableToolbar
            format="dd/MM/yyyy"
            margin="dense"
            inputVariant="outlined"
            id="date-picker-inline"
            label={t("start_date")}
            value={initialDate}
            onChange={data => {
              try {
                setInitialDate(data);
                onChangeFirstDate(new Date(data).toISOString().split("T")[0]);
              } catch (err) {
                onChangeFirstDate(null);
              }
            }}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            inputProps={{
              margin: "dense"
            }}
            InputLabelProps={{
              shrink: true
            }}
            placeholder={"start_date_placeholder"}
            cancelLabel={i18next.t("generalLabels:cancel")}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <KeyboardDatePicker
            className={classes.textField1}
            disabled={period !== "period"}
            disableToolbar
            format="dd/MM/yyyy"
            margin="dense"
            inputVariant="outlined"
            id="date-picker-inline"
            label={t("end_date")}
            value={finalDate}
            onChange={data => {
              setFinalDate(data);

              onChangeFinalDate(new Date(data).toISOString().split("T")[0]);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            inputProps={{
              margin: "dense"
            }}
            InputLabelProps={{
              shrink: true
            }}
            placeholder={"end_date_placeholder"}
            cancelLabel={i18next.t("generalLabels:cancel")}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid item xs={12} md={2}>
        <ButtonClick onClick={() => search()} height={40}>
          {t("update")}
        </ButtonClick>
      </Grid>
    </Grid>
  );
}

DateRange.defaultProps = {
  onChangeFirstDate: () => {},
  onChangeFinalDate: () => {},
  onChangePeriod: () => {},
  search: () => {},
  currentPeriod: "current_month"
};

export default withTranslation("delivery")(
  withStyles(materialStyle)(DateRange)
);

import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import queryString from "query-string";

// Global
import Container from "../../components/Container";
import { materialStyle } from "../../styles";
import Spinner from "../../components/Spinner";
import StyledLink from "../../styles/components/StyledLink";
import Snackbar from "../../components/Snackbar";

// Material
import { Grid, Typography, withStyles, Paper } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ReportActions } from "../../store/ducks/reports";
import { Actions as DeliveryActions } from "../../store/ducks/delivery";
import { Actions as NotificationActions } from "../../store/ducks/notification";

// Components
import TiltedAxisTick from "./components/TiltedAxisTick";
import TiltedYAxisTick from "./components/TiltedYAxisTick";
import { colors } from "../../styles";

import {
  Title,
  ContainerLastDeliveries,
  ContainerTitleMoreItems,
  ContainerEmptyLastDeliveries,
  ContainerBox
} from "./styled";

import CardInfo from "../../components/CardInfo";

import { weekDay } from "../../utils/dates";
import checkHandoverEmail from "../../services/checkHandoverEmail";
import { withTranslation } from "react-i18next";
import DateRange from "../../components/DateRange";
import { constants } from "../../config";

class Home extends Component {
  state = {
    type: 1,
    query: "total",
    parameters: null,
    start_date: new Date(new Date().setDate(1)).toISOString().split("T")[0],
    final_date: new Date().toISOString().split("T")[0],
    period: "current_month",
    viewType: constants.viewTypes.day
  };

  t = this.props.t;

  handleSearch = () => {
    const { start_date, final_date } = this.state;
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;
    this.setState({
      parameters
    });

    localStorage.setItem("selectedButton", "");
    const { reportsGeneralRequest } = this.props;
    const query = queryString.stringify({
      start_date,
      final_date
    });

    reportsGeneralRequest(query);
  };

  componentWillMount() {
    this.handleSearch();
  }

  handleTotal = obj => {
    const total = obj && Object.keys(obj).includes("total") ? obj.total : 0;

    return total;
  };

  handleChange = key => ({ target: { value } }) =>
    this.setState({ [key]: value });

  handleCountDeliveriesAmount = (type, model = "default") => {
    const { reports } = this.props;

    let _data = reports.data || [{ ...reports.subTeamReportDaily }];

    if (model === "rental") {
      if (!reports.rental) {
        return false;
      }

      _data = reports.rental;
    }

    if (model === "orders") {
      if (!reports.orders) {
        return false;
      }

      _data = reports.orders;
    }

    let amounts = null;

    if (type === "delivery") {
      amounts = _data.map(item => item.deliveryAmount);
    } else if (type === "total") {
      if (!reports || !_data.length) return 0;

      const deliveries = _data.map(item => item.deliveryAmount);
      if (!deliveries.length) {
        return "0";
      }
      const deliveriesAmount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return deliveriesAmount;
    } else if (type === "totalDeposit") {
      if (!reports || !_data.length) return 0;

      const deliveries = _data.map(item => item.deliveryDepositAmount);
      if (!deliveries.length) {
        return "0";
      }
      const deliveriesAmount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return deliveriesAmount;
    } else if (type === "totalCollect") {
      if (!reports || !_data.length) return 0;

      const deliveries = _data.map(item => item.deliveryCollectAmount);
      if (!deliveries.length) {
        return "0";
      }
      const deliveriesAmount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return deliveriesAmount;
    } else if (type === "totalFinished") {
      if (!reports || !_data.length) return 0;

      const deliveries = _data.map(item => item.deliveryFinishAmount);
      if (!deliveries.length) {
        return "0";
      }
      const deliveriesAmount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return deliveriesAmount;
    } else if (type === "time") {
      amounts = !_data.length ? [] : _data.map(item => item.timeAmount);
    } else if (type === "average") {
      amounts = !_data.length ? [] : _data.map(item => item.timeAverage);
    } else if (type === "attributes") {
      if (_data.length >= 1) {
        let data = [];

        const attributes = _data.map(i => JSON.parse(i.attributes));
        attributes.map(
          item =>
            item.length &&
            item.map(i => {
              const result = data.findIndex(e => e.day === i.date);
              if (result === -1) {
                return data.push({
                  day: i.date,
                  value: i.total
                });
              } else {
                return (data[result].value += i.total);
              }
            })
        );
        return data;
      } else {
        let data = [];
        if (_data.length) {
          data = JSON.parse(_data[0].attributes).map(item => {
            return { day: item.date, value: item[this.state.query] };
          });
        }
        return data;
      }
    } else if (type === "amount") {
      if (!_data.length) return 0;

      const deliveries = _data.map(item => item.rentalAmount);
      if (!deliveries.length) {
        return "0";
      }
      const amount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return amount;
    } else if (type === "rentalAmountActive") {
      if (!_data.length) return 0;

      const deliveries = _data.map(item => item.rentalAmountActive);
      if (!deliveries.length) {
        return "0";
      }
      const amount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return amount;
    } else if (type === "amountOrder") {
      if (!_data.length) return 0;

      const deliveries = _data.map(item => item.amount);
      if (!deliveries.length) {
        return "0";
      }
      const amount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return amount;
    } else if (type === "activeOrder") {
      if (!_data.length) return 0;

      const deliveries = _data.map(item => item.active);
      if (!deliveries.length) {
        return "0";
      }
      const amount = deliveries.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      return amount;
    }
    if (!amounts.length) {
      return "0d 0h e 0m";
    }

    const result = amounts.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
    const day = Math.floor(result / (1000 * 60 * 60 * 24));
    const hour = parseInt((result / (1000 * 60 * 60)) % 24);
    const min = parseInt((result / (1000 * 60)) % 60);

    return `${day}d ${hour}h e ${min}m`;
  };

  showDeliveryChartPerformance() {
    const { reports } = this.props;
    const { viewType } = this.state;

    if (!Object.keys(reports.data.groupedDate)) {
      return (
        <p>
          O sitema está calculando os valores do gráfico. Tente novamente mais
          tarde
        </p>
      );
    } else {
      const data = this.handleCountDeliveriesAmount("attributes", "default");

      if (!data) {
        return (
          <p>
            O sitema está calculando os valores do gráfico. Tente novamente mais
            tarde
          </p>
        );
      }
      return (
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="home-content-sm__chart"
        >
          <BarChart
            data={Object.keys(
              viewType === constants.viewTypes.month
                ? reports.data.groupedMonth
                : reports.data.groupedDate
            ).map(key => {
              const refData =
                viewType === constants.viewTypes.month
                  ? reports.data.groupedMonth
                  : reports.data.groupedDate;

              return {
                name: key, // XAxis
                total: refData[key].total
              };
            })}
            margin={{ top: 10, right: 0, bottom: 40, left: -20 }}
            className="barchart-home"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={<TiltedAxisTick />} interval={0} />
            <YAxis interval={1} tick={<TiltedYAxisTick />} />

            <Tooltip
              formatter={value => [value, "Total"]}
              labelFormatter={value => {
                const temp = value.split("-");
                const date = new Date(
                  new Date().getUTCFullYear(),
                  temp[1] - 1,
                  temp[0]
                ).getDay();

                return `${weekDay[date]}, ${value}`;
              }}
            />
            <Bar
              barSize={20}
              dataKey={this.state.query}
              fill={colors.primary}
            />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  }

  showRentalChartPerformance() {
    const { reports } = this.props;
    const { viewType } = this.state;

    if (!Object.keys(reports.rental.groupedByDate).length) {
      return (
        <p>
          O sitema está calculando os valores do gráfico. Tente novamente mais
          tarde
        </p>
      );
    } else {
      return (
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="home-content-sm__chart"
        >
          <BarChart
            data={Object.keys(
              viewType === constants.viewTypes.month
                ? reports.rental.groupedByMonth
                : reports.rental.groupedByDate
            ).map(key => {
              const refData =
                viewType === constants.viewTypes.month
                  ? reports.rental.groupedByMonth
                  : reports.rental.groupedByDate;

              return {
                name: refData[key].dia, // XAxis
                total: refData[key].total
              };
            })}
            margin={{ top: 10, right: 0, bottom: 40, left: -20 }}
            className="barchart-home"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={<TiltedAxisTick />} interval={0} />
            <YAxis interval={1} tick={<TiltedYAxisTick />} />

            <Tooltip
              formatter={value => [value, "Total"]}
              labelFormatter={value => {
                const date = new Date(value).getDay();

                return `${weekDay[date]}, ${value}`;
              }}
            />
            <Bar
              barSize={20}
              dataKey={this.state.query}
              fill={colors.primary}
            />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  }

  showOrdersChartPerformance() {
    const { reports } = this.props;
    const { viewType } = this.state;

    if (!Object.keys(reports.orders.groupedByDate).length) {
      return (
        <p>
          O sitema está calculando os valores do gráfico. Tente novamente mais
          tarde
        </p>
      );
    } else {
      return (
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="home-content-sm__chart"
        >
          <BarChart
            data={Object.keys(
              viewType === constants.viewTypes.month
                ? reports.orders.groupedByMonth
                : reports.orders.groupedByDate
            ).map(key => {
              const refData =
                viewType === constants.viewTypes.month
                  ? reports.orders.groupedByMonth
                  : reports.orders.groupedByDate;

              return {
                name: refData[key].dia, // XAxis
                total: refData[key].total
              };
            })}
            margin={{ top: 10, right: 0, bottom: 40, left: -20 }}
            className="barchart-home"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={<TiltedAxisTick />} interval={0} />
            <YAxis interval={1} tick={<TiltedYAxisTick />} />
            {/* <Legend
              margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
              wrapperStyle={{
                position: "inherit",
                bottom: 50
              }}
              formatter={() => {
                const { query } = this.state;
                let message = "total por dia";

                switch (query) {
                  case "timeAverage":
                    message = "tempo médio em horas";
                    break;
                  case "media":
                    message = "tempo médio utilizado em horas";
                    break;
                  case "timeAmount":
                    message = "tempo utilizado em horas";
                    break;
                  default:
                    return message;
                }

                return message;
              }}
            /> */}
            <Tooltip
              formatter={value => [value, "Total"]}
              labelFormatter={value => {
                const temp = value.split("-");
                const date = new Date(
                  new Date().getUTCFullYear(),
                  temp[1] - 1,
                  temp[0]
                ).getDay();

                return `${weekDay[date]}, ${value}`;
              }}
            />
            <Bar
              barSize={20}
              dataKey={this.state.query}
              fill={colors.primary}
            />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  }

  latestDeliveryItem = (delivery, label) => {
    let data = null;

    if (!delivery.business) {
      data = delivery.team.name;
    } else {
      data = delivery.business.name;
    }

    return (
      <Typography component="p">
        <b>{label}</b> {data}
      </Typography>
    );
  };

  getBusinessColectorInCompartmentOpening = compartmentOpening => {
    const open = compartmentOpening.find(
      item => item.business && parseInt(item.business.type) === 2
    );

    if (!open) return null;
    return (
      <Typography component="p">
        <b>Coletado por: </b>
        {open.business.name}
      </Typography>
    );
  };

  renderRentalCards = () => {
    const { classes, reports } = this.props;
    const { parameters } = this.state;

    return !Boolean(parameters.useRentalOrder) &&
      !checkHandoverEmail() ? null : (
      <ContainerBox>
        <Grid
          container
          direction="row"
          spacing={3}
          style={{
            marginBottom: 10
          }}
        >
          <Grid item md={12} xs={12}>
            <Title>{this.t("titleRental")}</Title>
          </Grid>

          <Grid item md={3} xs={12}>
            <CardInfo
              label="total"
              classes={classes.cardInfo}
              numberInfo={this.handleTotal(reports.rental)}
              labelColor={colors.primary}
              // subLabel={"periodo x"}
              status={null}
              params=""
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CardInfo
              label={this.t("active")}
              numberInfo={this.handleTotal(
                reports.rental.groupedByStatusData.active
              )}
              // subLabel="todos os meses"
              status={null}
              labelColor={colors.primary}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CardInfo
              label={this.t("finished")}
              numberInfo={this.handleTotal(
                reports.rental.groupedByStatusData.finished
              )}
              // subLabel="todos os meses"
              labelColor={colors.primary}
              status={null}
            />
          </Grid>
        </Grid>
      </ContainerBox>
    );
  };

  renderDeliveryCards = () => {
    const { classes, reports } = this.props;
    const { parameters } = this.state;

    return Boolean(parameters.useRentalOrder) &&
      !checkHandoverEmail() ? null : (
      <Grid
        container
        direction="row"
        spacing={3}
        style={{
          marginBottom: 10
        }}
      >
        <Grid item md={12} xs={12}>
          <Title>{this.t("title")}</Title>
        </Grid>
        <Grid item md={3} xs={12}>
          <CardInfo
            label={"total"}
            classes={classes.cardInfo}
            numberInfo={this.handleTotal(reports.data)}
            labelColor={colors.primary}
            // subLabel={`${
            //   this.t("deliveries") === "This month deliveries"
            //     ? monthNamesEn[new Date().getMonth()].toLowerCase()
            //     : monthNames[new Date().getMonth()].toLowerCase()
            // } de ${new Date().getFullYear()}`}
            status={!Boolean(parameters.useRentalOrder) ? "all" : null}
            params=""
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <CardInfo
            label={this.t("waiting_deposit")}
            numberInfo={this.handleTotal(
              reports.data.groupedStatusData.wait_to_deposit
            )}
            // subLabel={this.t("every_month")}
            status={"open"}
            labelColor={colors.primary}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CardInfo
            label={this.t("waiting_collect")}
            numberInfo={this.handleTotal(
              reports.data.groupedStatusData.wait_to_collect
            )}
            // subLabel={this.t("every_month")}
            status="2"
            labelColor={colors.primary}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CardInfo
            label={
              !Boolean(parameters.useRentalOrder)
                ? this.t("waiting_to_finish")
                : this.t("finished")
            }
            numberInfo={
              this.handleTotal(reports.data) -
              (this.handleTotal(
                reports.data.groupedStatusData.wait_to_collect
              ) +
                this.handleTotal(
                  reports.data.groupedStatusData.wait_to_deposit
                ) +
                this.handleTotal(reports.data.groupedStatusData.outros))
            }
            // subLabel={this.t("every_month")}
            labelColor={colors.primary}
            status={"active"}
          />
        </Grid>
      </Grid>
    );
  };

  renderUserB2CCards = () => {
    const { classes, reports } = this.props;
    const { parameters } = this.state;

    return !Boolean(parameters.activeOrderFee) &&
      !checkHandoverEmail() ? null : (
      <Grid
        container
        direction="row"
        spacing={3}
        style={{
          marginBottom: 10
        }}
      >
        <Grid item md={12} xs={12}>
          <Title>{this.t("licensedData")}</Title>
        </Grid>

        <Grid item md={3} xs={12}>
          <CardInfo
            label="total"
            classes={classes.cardInfo}
            numberInfo={this.handleTotal(reports.orders)}
            labelColor={colors.primary}
            // subLabel={`${monthNames[
            //   new Date().getMonth()
            // ].toLowerCase()} de ${new Date().getFullYear()}`}
            status={null}
            params=""
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CardInfo
            label={this.t("active")}
            numberInfo={this.handleTotal(
              reports.orders.groupedByStatusData.active
            )}
            // subLabel="todos os meses"
            status={"open"}
            labelColor={colors.primary}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CardInfo
            label={this.t("finished")}
            numberInfo={this.handleTotal(
              reports.orders.groupedByStatusData.finished
            )}
            // subLabel="todos os meses"
            status="2"
            labelColor={colors.primary}
          />
        </Grid>
      </Grid>
    );
  };

  renderDeliveryPerformance = () => {
    const { classes } = this.props;
    const { parameters } = this.state;
    return Boolean(parameters.useRentalOrder) &&
      !checkHandoverEmail() ? null : (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Paper
          className={[classes.paperHomeGrah, "paperHomeGrah"]}
          id="home-content-sm__performance"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Title>{this.t("performance_chart_title")}</Title>
          </div>
          {this.showDeliveryChartPerformance()}
        </Paper>
      </Grid>
    );
  };

  renderRentalPerformance = () => {
    const { classes } = this.props;
    const { parameters } = this.state;

    return !Boolean(parameters.useRentalOrder) &&
      !checkHandoverEmail() ? null : (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Paper
          className={[classes.paperHomeGrah, "paperHomeGrah"]}
          id="home-content-sm__performance"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Title>Performance de guarda-volumes</Title>
          </div>
          {this.showRentalChartPerformance()}
        </Paper>
      </Grid>
    );
  };

  renderUserB2CPerformance = () => {
    const { classes } = this.props;
    const { parameters } = this.state;
    return !Boolean(parameters.activeOrderFee) &&
      !checkHandoverEmail() ? null : (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Paper
          className={[classes.paperHomeGrah, "paperHomeGrah"]}
          id="home-content-sm__performance"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Title>Performance de licenciamento neste mês</Title>
          </div>
          {this.showOrdersChartPerformance()}
        </Paper>
      </Grid>
    );
  };

  renderLatestDeliveries = () => {
    const { delivery, classes } = this.props;
    return (
      <Grid item xs={12} sm={12} className="home-content-sm__lockes-1">
        <Paper className={classes.paperScroll}>
          <ContainerTitleMoreItems className="home-content-sm__lockes-1">
            <Title center>últimas entregas criadas</Title>
            <StyledLink
              style={{ color: colors.primary, fontSize: 15 }}
              to={`/delivery`}
            >
              ver mais+
            </StyledLink>
          </ContainerTitleMoreItems>
          {!delivery.loading && (
            <ContainerLastDeliveries>
              {delivery.data.length ? (
                delivery.data.map(item => (
                  <>
                    <Paper className={classes.paperItemDelivery}>
                      <Typography component="p">
                        <b>entrega:</b>{" "}
                        <StyledLink
                          style={{ color: colors.primary }}
                          to={`/delivery/edit/${item.id}`}
                        >
                          {`${
                            item.customerReferenceId.length > 15
                              ? item.customerReferenceId.slice(0, 15) + "..."
                              : item.customerReferenceId
                          }`}
                        </StyledLink>
                      </Typography>
                      {this.latestDeliveryItem(item, "criado por:")}
                      {item.idBusiness &&
                        this.latestDeliveryItem(item, "depositado por:")}
                      {item.businessColector && (
                        <Typography component="p">
                          <b>coletador por:</b> {item.businessColector.name}
                        </Typography>
                      )}
                      {item.compartmentOpening &&
                      item.compartmentOpening.length &&
                      !item.businessColector
                        ? this.getBusinessColectorInCompartmentOpening(
                            item.compartmentOpening
                          )
                        : ""}
                    </Paper>
                    <br />
                  </>
                ))
              ) : (
                <ContainerEmptyLastDeliveries className="home-content-sm__locacao-feita">
                  <small>nenhuma entrega feita</small>
                </ContainerEmptyLastDeliveries>
              )}
            </ContainerLastDeliveries>
          )}
        </Paper>
      </Grid>
    );
  };

  render() {
    const { reports } = this.props;

    return (
      <Container>
        {reports.error && (
          <Snackbar
            open={true}
            message="Um erro aconteceu"
            variant="error"
            onClose={this.props.resetErrorReports}
          />
        )}
        {reports.loading ? (
          <Spinner />
        ) : (
          <>
            <DateRange
              onChangeFirstDate={firstData =>
                this.setState({
                  start_date: firstData
                })
              }
              onChangeFinalDate={firstData =>
                this.setState({
                  final_date: firstData
                })
              }
              search={() => this.handleSearch()}
              currentPeriod={this.state.period}
              onChangePeriod={period => this.setState({ period })}
            />
            {this.renderDeliveryCards()}
            {this.renderRentalCards()}
            {this.renderUserB2CCards()}

            <Grid container spacing={2} className="home-content-sm__lockes">
              {this.renderDeliveryPerformance()}
              {this.renderRentalPerformance()}
              {this.renderUserB2CPerformance()}
              {/* {this.renderLatestDeliveries()} */}
            </Grid>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  delivery: state.delivery,
  notification: state.notification
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReportActions,
      ...DeliveryActions,
      ...NotificationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("home")(withStyles(materialStyle)(Home)));

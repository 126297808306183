export const Types = {
  reportsGeneralRequest: "reportsGeneral/Request",
  reportsGeneralSuccess: "reportsGeneral/Success",
  reportsGeneralError: "reportsGeneral/Error",

  reportsGeneralDaysRequest: "reportsGeneralDays/Request",
  reportsGeneralDaysSuccess: "reportsGeneralDays/Success",
  reportsGeneralDaysError: "reportsGeneralDays/Error",

  reportsRequest: "reports/Request",
  reportsSuccess: "reports/Success",
  reportsError: "reports/Error",

  subTeamReportsDailyRequest: "subTeamReportsDaily/Request",
  subTeamReportsDailySuccess: "subTeamReportsDaily/Success",
  subTeamReportsDailyError: "subTeamReportsDaily/Error",

  reportsGetDeliveriesRequest: "reportsGetDeliveries/Request",
  reportsGetDeliveriesSuccess: "reportsGetDeliveries/Success",
  reportsGetDeliveriesError: "reportsGetDeliveries/Error",

  reportsRentalRequest: "reportsRental/Request",
  reportsRentalSuccess: "reportsRental/Success",
  reportsRentalError: "reportsRental/Error",

  reportsOrdersRequest: "reportsOrders/Request",
  reportsOrdersSuccess: "reportsOrders/Success",
  reportsOrdersError: "reportsOrders/Error",

  handleChangeReports: "handleChange/Reports",

  resetErrorReports: "resetErrorReports"
};

const INITIAL_STATE = {
  data: {
    total: 0,
    groupedStatusData: {
      wait_to_deposit: {
        total: 0
      },
      finished: {
        total: 0
      },
      wait_to_collect: {
        total: 0
      },
      outros: {
        total: 0
      }
    },
    groupedByDateAndStatus: [],
    groupedDate: {},
    groupedMonth: {}
  },
  loading: false,
  loadingDays: false,
  error: null,
  snackbarVisible: false,
  deliveries: {
    data: [],
    total: 0,
    perPage: 10,
    page: 1,
    pastPage: 1
  },
  daysData: [],
  queryString: null,
  subTeamReportDaily: null,
  rental: {
    total: 0,
    groupedByDate: {},
    groupedByMonth: {},
    groupedByStatusData: {
      active: {
        total: 0
      },
      finished: {
        total: 0
      }
    }
  },
  orders: {
    total: 0,
    groupedByDate: {},
    groupedByMonth: {},
    groupedByStatusData: {
      active: {
        total: 0
      },
      finished: {
        total: 0
      }
    }
  }
};

export default function reports(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.resetErrorReports:
      return {
        ...state,
        loading: false,
        error: INITIAL_STATE.error
      };

    case Types.handleChangeReports:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    case Types.reportsRentalRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.reportsRentalSuccess:
      return {
        ...state,
        loading: false,
        rental: action.payload.data
      };

    case Types.reportsRentalError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.reportsOrdersRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.reportsOrdersSuccess:
      return {
        ...state,
        loading: false,
        orders: action.payload.data
      };

    case Types.reportsOrdersError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.reportsGeneralRequest:
      return {
        ...state,
        data: INITIAL_STATE.data,
        loading: true,
        error: null
      };

    case Types.reportsGeneralSuccess:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload.data
        }
      };

    case Types.reportsGeneralError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.reportsRequest:
      return {
        ...state,
        data: INITIAL_STATE.data,
        loading: true,
        error: null,
        snackbarVisible: INITIAL_STATE.snackbarVisible,
        queryString: action.payload.data
      };

    case Types.reportsSuccess:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };

    case Types.reportsError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.subTeamReportsDailyRequest:
      return {
        ...state,
        error: INITIAL_STATE.error,
        loading: true,
        subTeamReportDaily: INITIAL_STATE.subTeamReportDaily
      };

    case Types.subTeamReportsDailySuccess:
      return {
        ...state,
        loading: false,
        subTeamReportDaily: action.payload
      };

    case Types.subTeamReportsDailyError:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case Types.reportsGetDeliveriesRequest:
      return {
        ...state,
        loading: true,
        deliveries: INITIAL_STATE.deliveries,
        error: INITIAL_STATE.error,
        snackbarVisible: INITIAL_STATE.snackbarVisible
      };

    case Types.reportsGetDeliveriesSuccess:
      return {
        ...state,
        loading: false,
        deliveries: action.payload.data
      };

    case Types.reportsGetDeliveriesError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.reportsGeneralDaysRequest:
      return {
        ...state,
        loadingDays: true,
        error: INITIAL_STATE.error,
        daysData: INITIAL_STATE.daysData,
        snackbarVisible: INITIAL_STATE.snackbarVisible
      };

    case Types.reportsGeneralDaysSuccess:
      return {
        ...state,
        loadingDays: false,
        daysData: action.payload.data
      };

    case Types.reportsGeneralDaysError:
      return {
        ...state,
        loadingDays: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  reportsGeneralRequest: data => ({
    type: Types.reportsGeneralRequest,
    payload: { data }
  }),

  reportsGeneralSuccess: data => ({
    type: Types.reportsGeneralSuccess,
    payload: { data }
  }),

  reportsGeneralError: error => ({
    type: Types.reportsGeneralError,
    payload: { error }
  }),

  reportsRequest: data => ({
    type: Types.reportsRequest,
    payload: { data }
  }),

  reportsSuccess: data => ({
    type: Types.reportsSuccess,
    payload: { data }
  }),

  reportsError: error => ({
    type: Types.reportsError,
    payload: { error }
  }),

  reportsGetDeliveriesRequest: data => ({
    type: Types.reportsGetDeliveriesRequest,
    payload: { data }
  }),

  reportsGetDeliveriesSuccess: data => ({
    type: Types.reportsGetDeliveriesSuccess,
    payload: { data }
  }),

  reportsGetDeliveriesError: error => ({
    type: Types.reportsGetDeliveriesError,
    payload: { error }
  }),

  reportsGeneralDaysRequest: data => ({
    type: Types.reportsGeneralDaysRequest,
    payload: { data }
  }),

  reportsGeneralDaysSuccess: data => ({
    type: Types.reportsGeneralDaysSuccess,
    payload: { data }
  }),

  reportsGeneralDaysError: data => ({
    type: Types.reportsGeneralDaysError,
    payload: { data }
  }),

  resetErrorReports: () => ({
    type: Types.resetErrorReports
  }),

  handleChangeReports: (key, value) => ({
    type: Types.handleChangeReports,
    payload: { key, value }
  }),

  subTeamReportsDailyRequest: payload => ({
    type: Types.subTeamReportsDailyRequest,
    payload
  }),

  subTeamReportsDailySuccess: payload => ({
    type: Types.subTeamReportsDailySuccess,
    payload
  }),

  subTeamReportsDailyError: error => ({
    type: Types.subTeamReportsDailyError,
    payload: error
  }),

  reportsRentalRequest: () => ({
    type: Types.reportsRentalRequest
  }),

  reportsRentalSuccess: data => ({
    type: Types.reportsRentalSuccess,
    payload: { data }
  }),

  reportsRentalError: error => ({
    type: Types.reportsRentalError,
    payload: { error }
  }),

  reportsOrdersRequest: () => ({
    type: Types.reportsOrdersRequest
  }),

  reportsOrdersSuccess: data => ({
    type: Types.reportsOrdersSuccess,
    payload: { data }
  }),

  reportsOrdersError: error => ({
    type: Types.reportsOrdersError,
    payload: { error }
  })
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { constants } from "../../config";
import { materialStyle } from "../../styles";

const useStyles = makeStyles(materialStyle);

export default function ButtonClick({ onClick, children, ...props }) {
  const classes = useStyles(materialStyle);
  const defaultStyle = props.disabled
    ? constants.buttonDefaultDisabledStyle
    : constants.buttonDefaultStyle;

  return (
    <Button
      onClick={() => onClick()}
      variant="contained"
      className={classes.button}
      style={{
        ...defaultStyle,
        ...{
          height: 40,
          marginLeft: 8
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

ButtonClick.defaultProps = {
  onClick: () => {}
};

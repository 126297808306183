import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import Badge from "@material-ui/core/Badge";

import SimpleActionButton from "../SimpleActionButton";
import defaultConfig from "../../config/default";
import formatDate from "../../functions/formatDate.js";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as OrderActions } from "../../store/ducks/orders";
import { withTranslation } from "react-i18next";
import { TableContainer } from "@material-ui/core";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class OrdersPaginationTable extends Component {
  t = this.props.t;

  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };

  handleChangePage = (event, newPage) => {
    const { getActiveOrdersRequest } = this.props;

    const query = `page=${newPage + 1}`;

    getActiveOrdersRequest(query);
  };

  handleChangeRowsPerPage = event => {
    const { getActiveOrdersRequest } = this.props;

    getActiveOrdersRequest(`perPage=${parseInt(event.target.value)}`);

    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  render() {
    const classes = this.useStyles2();
    const { orders, history } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{this.t("common:client")}</TableCell>
                  <TableCell align="left">{this.t("common:hours")}</TableCell>
                  <TableCell align="left">{this.t("common:locker")}</TableCell>
                  <TableCell align="left">{this.t("common:door")}</TableCell>
                  <TableCell align="left">{this.t("common:date")}</TableCell>
                  <TableCell align="left">
                    {this.t("common:finishedAt")}
                  </TableCell>
                  <TableCell align="left">{this.t("common:actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.data &&
                  orders.data.map(
                    row =>
                      row.toUser &&
                      row.locker && (
                        <TableRow key={1}>
                          <TableCell align="left">
                            {`${row.toUser.name} - ${row.toUser.email}`}
                          </TableCell>
                          <TableCell align="left">{row.boughtHours}</TableCell>
                          <TableCell align="left">
                            {row.locker.address}
                          </TableCell>
                          <TableCell align="left">
                            {row.compartment.compartmentNumber}
                          </TableCell>
                          <TableCell align="left">
                            {formatDate(row.createdAt)}
                          </TableCell>
                          <TableCell align="center">
                            {row.finishedAt || (
                              <Badge badgeContent="Ativa" color="secondary" />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <SimpleActionButton
                              id={Math.random()}
                              actions={[
                                () => history.push(`/orders/edit/${row.id}`)
                              ]}
                              options={["Visualizar"]}
                            />
                          </TableCell>
                        </TableRow>
                      )
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
                    colSpan={3}
                    count={orders.total}
                    rowsPerPage={orders.perPage}
                    page={orders.page - 1}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true
                    }}
                    labelRowsPerPage={this.t("common:lines_per_page")}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  orders: state.orders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OrderActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("order")(OrdersPaginationTable));

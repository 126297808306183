import React, { Component } from "react";
import queryString from "query-string";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";

// Global
import Container from "../../components/Container";
import CustomerFeedbackTable from "../../components/CustomerFeedbackTable";
import Spinner from "../../components/Spinner";
import { materialStyle } from "../../styles";

// Material
import { Grid, Button, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CustomerFeedbackActions } from "../../store/ducks/customerFeedback";

import { ExportExcelFeedback } from "../../components/ExportExcel";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { withTranslation } from "react-i18next";
class CustomerFeedback extends Component {
  t = this.props.t;
  state = {
    options: [
      {
        note: 3,
        text: this.t("great")
      },
      {
        note: 2,
        text: this.t("good")
      },
      {
        note: 1,
        text: this.t("bad")
      }
    ],
    note: -1,
    initialDate: new Date(),
    finalDate: new Date()
  };

  componentWillMount() {
    this.setInitialState();
  }

  convertDateToSearch = date => {
    let tmp = new Date(date);
    tmp.setHours(tmp.getHours() + 3);
  };

  componentDidMount() {
    const { customerFeedbackRequest } = this.props;
    const { initialDate, finalDate } = this.state;
    customerFeedbackRequest(
      queryString.stringify({
        initialDate: this.convertDateToSearch(initialDate),
        finalDate: this.convertDateToSearch(finalDate)
      })
    );
  }

  setInitialState = () => {
    this.setState({
      finalDate: this.getFormatedDate(new Date(), false),
      initialDate: this.getFormatedDate(new Date(), true),
      note: -1
    });
  };

  getFormatedDate = (date, start = false) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${
      start ? "00:00:00" : "23:59:59"
    }`;
  };

  handleChange = key => event => this.setState({ [key]: event.target.value });

  handleState = () => {
    const { note, initialDate, finalDate } = this.state;

    const { customerFeedback } = this.props;

    const data = {
      page: 1,
      perPage: customerFeedback.perPage,
      initialDate: this.convertDateToSearch(initialDate),
      finalDate: this.convertDateToSearch(finalDate)
    };

    if (note !== -1) {
      data.note = parseInt(note);
    }

    return data;
  };

  handleSearch = () => {
    const { customerFeedbackRequest } = this.props;

    customerFeedbackRequest(queryString.stringify(this.handleState()));
  };

  handleClear = () => {
    const { customerFeedbackRequest } = this.props;

    customerFeedbackRequest(
      queryString.stringify({
        initialDate: this.getFormatedDate(new Date(), true),
        finalDate: this.getFormatedDate(new Date(), false)
      })
    );

    this.setInitialState();
  };

  render() {
    const { classes, customerFeedback } = this.props;

    return (
      <Container title={this.t("title")} id="title-sm__h3">
        <ThemeTextFildGreen>
          {customerFeedback.loading ? (
            <Spinner />
          ) : (
            <>
              <div className="helper-spacing-top-mobile" />

              <Grid container spacing={2} className="first-grid">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label={this.t("startDate")}
                      value={this.state.initialDate}
                      className={classes.textField1}
                      onChange={value =>
                        this.setState({
                          initialDate: this.getFormatedDate(value, true)
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      inputVariant="outlined"
                      autoOk
                    />
                  </Grid>
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label={this.t("endDate")}
                      className={classes.textField}
                      value={this.state.finalDate}
                      onChange={value =>
                        this.setState({
                          finalDate: this.getFormatedDate(value, false)
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      inputVariant="outlined"
                      autoOk
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="custom-css-outlined-input"
                      select
                      label={this.t("feedback")}
                      className={classes.textFieldLocker}
                      value={this.state.note}
                      onChange={this.handleChange("note")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      <MenuItem value={-1}>{this.t("common:all")}</MenuItem>
                      {this.state.options.map(item => (
                        <MenuItem key={item.note} value={item.note}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Button
                onClick={this.handleSearch}
                variant="contained"
                className={classes.button1}
                style={{
                  backgroundColor: "#006660",
                  color: "#fff",
                  textTransform: "lowercase",
                  fontSize: 14,
                  height: 35
                }}
              >
                {this.t("common:search")}
              </Button>
              <Button
                onClick={this.handleClear}
                variant="contained"
                className={classes.button}
                style={{
                  backgroundColor: "#006660",
                  color: "#fff",
                  textTransform: "lowercase",
                  fontSize: 14,
                  height: 35
                }}
              >
                {this.t("common:clear")}
              </Button>
              <ExportExcelFeedback
                data={customerFeedback.data}
                classes={classes}
              />
              <br />
              <CustomerFeedbackTable
                history={this.props.history}
                getState={this.handleState}
              />
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  compartments: state.compartment,
  customerFeedback: state.customerFeedback
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CustomerFeedbackActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(materialStyle)(
    withTranslation("customerFeedbackTable")(CustomerFeedback)
  )
);
